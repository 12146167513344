var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"room-list",attrs:{"fluid":""}},[_c('div',{staticClass:"room-list__links",staticStyle:{"max-width":"80%"}},[_c('v-tabs',{attrs:{"show-arrows":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.rooms),function(room){return _c('v-tab',{key:room.id,attrs:{"to":{
          name: 'RoomId',
          params: { roomId: room.id },
          query: {
            project_id: _vm.$route.query.project_id,
            quota_id: _vm.$route.query.quota_id,
          },
        }}},[_vm._v(" "+_vm._s(room.name)+" ")])}),1)],1),_c('div',{staticClass:"room-list__action"},[_c('div',[_c('v-btn',{attrs:{"outlined":"","icon":"","color":"primary"},on:{"click":function($event){return _vm.$emit('addNewRoom')}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-plus")])],1),(_vm.displaySaveButton)?_c('v-btn',{attrs:{"color":"info","depressed":"","loading":_vm.loading},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.textButton)+" ")]):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }