<template>
  <v-container fluid class="room-list">
    <div class="room-list__links" style="max-width: 80%">
      <v-tabs v-model="tab" show-arrows>
        <v-tab
          v-for="room in rooms"
          :key="room.id"
          :to="{
            name: 'RoomId',
            params: { roomId: room.id },
            query: {
              project_id: $route.query.project_id,
              quota_id: $route.query.quota_id,
            },
          }"
        >
          {{ room.name }}
        </v-tab>
      </v-tabs>
    </div>
    <div class="room-list__action">
      <div>
        <v-btn outlined icon color="primary" @click="$emit('addNewRoom')">
          <v-icon color="primary">mdi-plus</v-icon>
        </v-btn>
        <v-btn
          v-if="displaySaveButton"
          color="info"
          depressed
          :loading="loading"
          @click="save"
        >
          {{ textButton }}
        </v-btn>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "RoomList",
  props: {
    rooms: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      tab: null,
      loading: false,
    };
  },
  watch: {
    rooms: {
      immediate: true,
      handler(newVal) {
        if (newVal.length > 0 && !this.$route.params.roomId) {
          this.$router.push({
            name: "RoomId",
            params: { roomId: newVal[0].id },
            query: {
              project_id: $route.query.project_id,
              quota_id: this.$route.query.quota_id,
            },
          });
        }
      },
    },
  },
  computed: {
    displaySaveButton() {
      return true;
    },
    textButton() {
      let text = "";
      switch (true) {
        case this.$route.name === "RoomId":
          text = "Voir le Devis";
          break;
        default:
          text = "Sauvegarder";
      }
      return text;
    },
  },
  methods: {
    save() {
      if (this.$route.name === "ServiceCategoryId") {
        this.$router.push({
          name: "RoomId",
          params: {
            roomId: this.$route.params.roomId,
          },
          query: {
            project_id: this.$route.query.project_id,
            quota_id: this.$route.query.quota_id,
          },
        });
      } else {
        this.$router.push({
          name: "QuotaSum",
          params: {
            quotaId: this.$route.query.quota_id,
          },
        });
      }
    },
  },
};
</script>

<style src="./RoomList.scss" lang="scss" scoped></style>